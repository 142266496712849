@font-face {
  font-family: 'Mashine-Bold';
  src: local('Mashine-Bold'), url('./assets/fonts/Mashine/Mashine-Bold.otf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Mashine-Light';
  src: local('Mashine-Bold'), url('./assets/fonts/Mashine/Mashine-Light.otf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Mashine-Regular';
  src: local('Mashine-Regular'),
    url('./assets/fonts/Mashine/Mashine-Regular.otf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),
    url('./assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'),
    url('./assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),
    url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'),
    url('./assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Mashine-Light', sans-serif;
  font-size: 1.8rem;
  line-height: 3.1rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Montserrat-Regular', sans-serif;
}
